



















































































.middle-box {
  width: 393px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 12px;
  padding: 10px;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";