.scroll-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 615px;
}
.scroll-top .item {
  width: 40px;
  height: 40px;
  color: var(--zs-aside-bar-text-color);
  background: var(--zs-aside-bar-bg);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.3s;
  font-size: 12px;
}
.scroll-top .item:last-child {
  margin-bottom: 0;
}
.scroll-top .icon {
  font-size: 22px;
  transition: all 0.3s;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
