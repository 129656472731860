

































































































































































































.popover-box {
  padding: 0;
  border-radius: 15px;
  background: transparent;
  border: none;
  .container {
    position: relative;
    width: 100%;
    min-height: 120px;
    border-radius: 15px;
    background: var(--zs-aside-bar-pop-bg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 19px 11px;
    border-radius: 16px;
    // &::before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 1;
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 50px;
    //   overflow: hidden;
    //   // background: var(--zs-aside-bar-pop-bg) no-repeat;
    //   background-size: 100%;
    //   border-radius: 16px 16px 0 0;
    // }

    // &::after {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   z-index: 1;
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 50px;
    //   overflow: hidden;
    //   // background: var(--zs-aside-bar-pop-bg) no-repeat;
    //   background-size: 100%;
    //   background-position: 0 -178px;
    //   border-radius: 0 0 16px 16px;
    // }
    .title {
      position: relative;
      z-index: 2;
      font-weight: 800;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      background: url("~@/assets/zs_imgs/popover-box-title-bg.png") no-repeat;
      background-size: contain;
      background-position: center center;
      margin-bottom: 22px;
    }
    .content {
      position: relative;
      z-index: 2;
      padding: 12px;
      border-radius: 8px;
      background: var(--zs-aside-bar-pop-content-bg);

      .gg-content {
        font-weight: 400;
        font-size: 12px;
        color: var(--zs-aside-bar-pop-content-color);
        line-height: 18px;
      }

      .friend-links {
        display: flex;
        flex-wrap: wrap;
        // padding-left: 8px;
        gap: 8px 8px;
        .link-item {
          width: 110px;
          height: 24px;
          background: var(--zs-aside-bar-pop-friend-bg);
          border-radius: 2px;
          font-weight: 400;
          font-size: 12px;
          color: var(--zs-aside-bar-pop-friend-color);
          line-height: 24px;
          padding: 0 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            opacity: 0.9;
          }
          img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }

      .manage-user-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 18px 16px;
        .user-item {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 6px;
          .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          .username {
            width: 100%;
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: var(--zs-aside-bar-pop-friend-color);
            line-height: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
          }
        }
      }

      .apply-admin {
        width: 216px;
        height: 32px;
        background: var(--zs-aside-bar-pop-apply-admin-bg);
        border-radius: 16px;
        font-size: 14px;
        color: var(--zs-aside-bar-pop-apply-admin-text-color);
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        margin: 34px auto 10px;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";