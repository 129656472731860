












































































































.calendar-activity {
  width: 100%;
  margin-bottom: 13px;
  .logo-title {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    // line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list {
    width: 100%;
    padding: 0 0 0 0;
    .scroll-wrapper {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      .scroll-content {
        display: inline-block;
      }
    }
  }

  .importance-list {
    flex-shrink: 0;
  }
  .normal-list {
    flex-shrink: 0;
  }

  .line1 {
    // margin-bottom: 12px;
  }
  .line1,
  .line2 {
    display: flex;
    gap: 8px;
  }

  .card {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    flex-shrink: 0;
    width: 168px;
    height: 80px;
    background: #2a323b;
    border-radius: 8px;
    overflow: hidden;
    &.inportance-card {
      width: 150px;
      height: 153px;
    }
    .banner {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #e3e3e3;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 79px;
        height: 37px;
        background: url("~@/assets/zs_imgs/activity-time-left-bg.png");
        background-size: 100% 100%;
      }
    }
    .time-left {
      display: flex;
      align-items: center;
      position: absolute;
      top: 2px;
      left: 2px;
      height: 16px;
      z-index: 1;
      display: inline-flex;
      padding: 0 8px 0 5px;
      // background: rgba(19, 23, 33, 0.7);
      // border-radius: 8px;
      font-weight: 400;
      font-size: 10px;
      color: #fff;

      .icon {
        width: 11px;
        height: 11px;
        margin-right: 2px;
        background: transparent;
      }
    }
    .tag {
      position: absolute;
      right: 2px;
      top: 2px;
      height: 16px;
      padding: 0 6px;
      background: #d27246;
      border-radius: 8px;
      font-size: 10px;
      color: #d5f9ff;
      line-height: 16px;
    }
    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 21px;
      background: rgba(48, 54, 82, 0.73);
      font-weight: 400;
      font-size: 12px;
      color: #d0ccc4;
      line-height: 21px;
      padding: 0 6px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      text-align: center;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";