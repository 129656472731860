


















































































































































































































































































































.qz-list {
  position: relative;
  width: 100%;
  padding: 10px 0;
  overflow: hidden;
  padding-bottom: 60px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    height: 34px;
    margin: 0 10px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: var(--zs-server-wrapper-bg);
    border-radius: 4px;

    .left {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: var(--zs-server-tab-color);
      line-height: 24px;
      .dot {
        display: block;
        width: 3px;
        height: 16px;
        background: var(--zs-qz-tag-bg);
        margin-right: 6px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--zs-qz-tag-icon-color);
      line-height: 16px;
      float: right;
      cursor: pointer;
      i {
        font-size: 8px;
        margin-left: 4px;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 14px 19px 13px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 158px;
      height: 32px;
      background: var(--zs-qz-btn-bg);
      border-radius: 16px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      outline: none;
      .icon {
        font-size: 20px;
        margin-right: 3px;
      }
    }
  }
}

.list-nav {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 11px;
  gap: 62px;
  border-bottom: 1px solid var(--zs-qz-nav-border-color);
  margin-bottom: 11px;
  .list-nav-item {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 21px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      color: var(--zs-qz-tab-active-color);
    }
  }

  .active-bar {
    position: absolute;
    top: 41px;
    left: 46px;
    width: 40px;
    height: 3px;
    background: var(--zs-qz-tab-active-color);
    border-radius: 2px;
    transition: all 0.3s;
  }
}

.list {
  padding: 0 10px;
  overflow: hidden;
  .list-item {
    display: block;
    padding: 4px 0 13px;
    border-bottom: 1px solid var(--zs-qz-nav-item-border-color);

    &:last-child {
      margin-bottom: 0;
    }
    .top {
      display: flex;
      margin-bottom: 14px;
      .left {
        flex: 1;
        width: 100%;
        font-weight: 400;
        font-size: 13px;
        color: var(--zs-qz-nav-item-text-color);
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        .is-top {
          color: #0096ff;
          margin-right: 4px;
        }
      }
      .right {
        flex-shrink: 0;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
    .middle {
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 15px;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user {
        display: flex;
        align-items: center;
        font-size: 11px;
        color: #999;
        line-height: 16px;
        .avatar {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
      .data {
        display: flex;
        align-items: center;
        gap: 24px;
        .data-item {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 11px;
          color: #999999;
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
        }
      }
    }
  }

  .quyunba-box {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .battle-banner {
    width: 100%;
    height: 60px;
    background: url("~@/assets/zs_imgs/battle/home_banner.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .ck-banner {
    width: 100%;
    height: 60px;
    background: url("~@/assets/zs_imgs/ck-entry-banner.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";