.zsca-wiki-home {
  width: 100%;
  min-height: 100vh;
  padding-top: 65px;
}
.zsca-wiki-home .bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: var(--zs-wiki-home-bg-img) no-repeat;
  background-size: 100% 100%;
}
.zsca-wiki-home .wiki-home-main {
  position: relative;
  width: 1216px;
  margin: 0 auto;
  background: var(--zs-wiki-home-main-bg);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  gap: 10px;
}
.zsca-wiki-home .wiki-home-main .left {
  width: 817px;
}
.zsca-wiki-home .wiki-home-main .left .top-box {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.zsca-wiki-home .wiki-home-main .left .bottom-box {
  width: 100%;
  background: var(--zs-wiki-banner-bg);
  padding: 10px;
  border-radius: 8px;
}
.zsca-wiki-home .wiki-home-main .right {
  width: 369px;
  background-color: var(--zs-wiki-banner-bg);
  border-radius: 8px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
