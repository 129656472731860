

























































/deep/ .shenqing-dialog {
  overflow: hidden;

  .el-dialog__header {
    padding: 0;
    background: @text-basic;

    .game-info {
      height: 60px;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      padding-left: 10px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid #fff;
        margin-right: 10px;
      }
    }

    .el-dialog__close {
      color: #fff;
    }
  }

  .title {
    color: #171717;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .input-box {
    margin-bottom: 30px;

    .text {
      color: #171717;
      font-size: 14px;

      span {
        color: @red;
      }
    }

    textarea {
      border: 1px solid @border-basic;
      border-radius: 4px;
      resize: none;
      height: 120px;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .ok-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";