.server-select-menu[data-v-6a62485c] {
  background: none !important;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.server-select-menu[data-v-6a62485c] .el-dropdown-menu__item {
  font-size: 13px;
  font-weight: 400;
  color: var(--zs-server-select-color);
  background: var(--zs-server-select-bg) !important;
  height: 23px;
  min-width: 72px;
  border-radius: 11px;
  margin-bottom: 4px;
  line-height: 23px;
  padding: 0 12px;
  font-size: 11px;
  border: 1px solid var(--zs-server-dropdown-border-color);
}
.server-select-menu[data-v-6a62485c] .el-dropdown-menu__item:focus {
  background-color: #292a30 !important;
}
.server-select-menu[data-v-6a62485c] .popper__arrow {
  display: none !important;
}
.server-select[data-v-6a62485c] {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 8px;
}
.server-select .wrapper[data-v-6a62485c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 34px;
  padding: 3px 5px;
  background-color: var(--zs-server-wrapper-bg);
  border-radius: 4px;
}
.server-select .wrapper .left[data-v-6a62485c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-server-tab-color);
  line-height: 20px;
}
.server-select .wrapper .left .dot[data-v-6a62485c] {
  width: 3px;
  height: 3px;
  background: var(--zs-server-tab-dot-bg);
  margin-right: 7px;
  border-radius: 50%;
}
.server-select .server-select-box[data-v-6a62485c] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: var(--zs-server-select-bg);
  border-radius: 4px;
  padding: 0 11px 0 14px;
  min-width: 79px;
  height: 26px;
  line-height: 26px;
  font-weight: 400;
  font-size: 11px;
  color: var(--zs-server-select-color);
}
.server-select .server-select-box .icon[data-v-6a62485c] {
  margin-left: 4px;
  color: var(--zs-server-select-color);
}
.ellipsis[data-v-6a62485c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-6a62485c] {
  *zoom: 1;
}
.clearfix[data-v-6a62485c]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-6a62485c] {
  float: left;
}
.fr[data-v-6a62485c] {
  float: right;
}

.card-pool[data-v-37b8e585] {
  width: 100%;
  margin-bottom: 13px;
}
.card-pool .logo-title[data-v-37b8e585] {
  height: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  color: var(--zs-card-pool-text-color);
  margin-bottom: 6px;
}
.card-pool .logo-title .left[data-v-37b8e585] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.card-pool .logo-title .left .icon[data-v-37b8e585] {
  margin-right: 5px;
  color: var(--zs-card-pool-icon-color);
  font-size: 24px;
}
.card-pool .logo-title .quilt-card[data-v-37b8e585] {
  height: 20px;
  width: 90px;
  color: #fff;
  background: var(--zs-qz-btn-bg) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAYAAAA+stv/AAAAAXNSR0IArs4c6QAAA41JREFUWEftl1loXVUUhr8fIypYEAcUFAUVFCpRkooT2CIOmCeNEA2oUEX7IG0VETGKA5b6pIKKPqUWShQjrVDU4EB8cMQ4YKs+tPWhKKKiKIhT1Sz3f9k3nJzsm5yEkuTBBffhnrPWXv9ew7/WEUssWmL/LD8AEdEl6Z/FisyMCETEDZK2LQmAiFgHPAlcIemtxQDRikBEHArcDDwNrbr4A7gGeE3SZESckvQeBE4E/sq/A8D3wEfAkcDplefW+R0Yl/TFbBeZloKIuA7YmgwvlvRh2zAi3ktgLgC+At4F/P8n4CjgVOAN4BzgsZozg1wt6YNOIEo10C9pR9UgIgaBv7Ozi4ALgWMrOv8ClwDDORJV882S7p0PgEOAo7MTO7LDVcBhc9TEM4Btb63pNQcQEQ7nCHBeroX51OFnwOM5hVW7IUmPNIpARLwADMzHay0NBu6irMoGSe6sotSL8BWgr6AZ7gjAALtymM8t6F2ZI3B85d1Nkp5tCsCt9kBB+WxJuypdcQSwBzippvswsBLorzwfkPRiUwCXpeJ7vaY86T6XZG6YkogwW15f0x0HHMVHK8/7JI01BbAC+DlXc9WmW9LuGgCH+9Xawb+ZRYF3Ks/NKW83AmCliPg0k0rVZkYYI8LEY926uG3fBJwmS4+kkl7rZYmIngJuq53qKt4oycXYkoh4CLi/AOB24GozYH53hiTXS1FKAMx6zxW03cv35dlg7t8LnFDQG021sQ8YyrPhGElOTWMAJwP7O+iPSro23d7AL00F51Y8C7iqEvJvc5u+7Bkh6fJOzospyOH9utBiOz0xJf1YPzB1xGmAB057PvQCE8AtkrYsBMDzgCdjW+6UVJ90085Nm9SmNLDaQ8ft6ZuvdcoWAmB9GkhPZMPdaTp2F259eB5Srnr/1qT+dxtbPJjuyKzpDmrGhG0nEdEDfJz/v5RYsD8ijsuO2hPSYS5NSDPmoKQvc1S86PRKcm3MkOJWHBEeq7/kTcfbjWvCee60Rf+aycfs6C7wOL8nneGWtM03acu6G9guyedNSce1PCK85bjSm8jnmcKH883PBO5yDWQA7ipzy1i9JmYD0IloZpRD7nvTr9e1EUl/5m7yYNtgZpXkKDZLQTY+P/H6+wUbh/CT7Ky1H0r6oXS4vzHMCZK87BZl1i+jiHA33Ah8V3E40b5hk9zMpbP8Ps3mQnyw3/8fgSWPwH9+eiAys6mX4QAAAABJRU5ErkJggg==) no-repeat;
  border-radius: 20px;
  line-height: 20px;
  background-position: 10px center;
  padding: 0 10px 0 30px;
  text-align: right;
  font-size: 10px;
  background-size: 16px 16px;
}
.card-pool .logo-title .right[data-v-37b8e585] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--zs-card-pool-more-color);
}
.card-pool .list[data-v-37b8e585] {
  width: 100%;
  padding: 0 0 0 0;
}
.card-pool .list .scroll-wrapper[data-v-37b8e585] {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.card-pool .list .scroll-wrapper .scroll-content[data-v-37b8e585] {
  display: inline-block;
}
.card-pool .card[data-v-37b8e585] {
  display: inline-block;
  width: 73px;
  height: 103px;
  background: var(--zs-card-pool-list-bg);
  border-radius: 8px;
  margin-right: 8px;
}
.card-pool .card .icon[data-v-37b8e585] {
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.card-pool .card .icon img[data-v-37b8e585] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  background: #e3e3e3;
}
.card-pool .card .icon .time-left[data-v-37b8e585] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 29px;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 11px 4px 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(42%, rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.72)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 42%, rgba(0, 0, 0, 0.72) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 42%, rgba(0, 0, 0, 0.72) 100%);
}
.card-pool .card .icon .time-left .icon[data-v-37b8e585] {
  width: 11px;
  height: 11px;
  margin-right: 2px;
  background: transparent;
}
.card-pool .card .icon .tag[data-v-37b8e585] {
  position: absolute;
  right: 4px;
  top: 4px;
  height: 16px;
  padding: 0 6px;
  background: #d27246;
  border-radius: 8px;
  font-size: 10px;
  color: #d5f9ff;
  line-height: 16px;
}
.card-pool .card .name[data-v-37b8e585] {
  width: 100%;
  height: 23px;
  padding: 0 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--zs-card-pool-name-color);
  line-height: 23px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ellipsis[data-v-37b8e585] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-37b8e585] {
  *zoom: 1;
}
.clearfix[data-v-37b8e585]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-37b8e585] {
  float: left;
}
.fr[data-v-37b8e585] {
  float: right;
}

.calendar-activity[data-v-84e7f580] {
  width: 100%;
  margin-bottom: 13px;
}
.calendar-activity .logo-title[data-v-84e7f580] {
  height: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-text-color);
  margin-bottom: 6px;
}
.calendar-activity .logo-title .left[data-v-84e7f580] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.calendar-activity .logo-title .left .icon[data-v-84e7f580] {
  margin-right: 5px;
  color: var(--zs-card-pool-icon-color);
  font-size: 24px;
}
.calendar-activity .logo-title .right[data-v-84e7f580] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--zs-card-pool-more-color);
}
.calendar-activity .list[data-v-84e7f580] {
  width: 100%;
  padding: 0 0 0 0;
}
.calendar-activity .list .scroll-wrapper[data-v-84e7f580] {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.calendar-activity .list .scroll-wrapper .scroll-content[data-v-84e7f580] {
  display: inline-block;
}
.calendar-activity .importance-list[data-v-84e7f580] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.calendar-activity .normal-list[data-v-84e7f580] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.calendar-activity .line1[data-v-84e7f580],
.calendar-activity .line2[data-v-84e7f580] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}
.calendar-activity .card[data-v-84e7f580] {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 168px;
  height: 80px;
  background: #2a323b;
  border-radius: 8px;
  overflow: hidden;
}
.calendar-activity .card.inportance-card[data-v-84e7f580] {
  width: 150px;
  height: 153px;
}
.calendar-activity .card .banner[data-v-84e7f580] {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.calendar-activity .card .banner img[data-v-84e7f580] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background: #e3e3e3;
}
.calendar-activity .card .banner .mask[data-v-84e7f580] {
  position: absolute;
  top: 0;
  left: 0;
  width: 79px;
  height: 37px;
  background: url(../img/activity-time-left-bg.95a378dc.png);
  background-size: 100% 100%;
}
.calendar-activity .card .time-left[data-v-84e7f580] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  z-index: 1;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 8px 0 5px;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
}
.calendar-activity .card .time-left .icon[data-v-84e7f580] {
  width: 11px;
  height: 11px;
  margin-right: 2px;
  background: transparent;
}
.calendar-activity .card .tag[data-v-84e7f580] {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 16px;
  padding: 0 6px;
  background: #d27246;
  border-radius: 8px;
  font-size: 10px;
  color: #d5f9ff;
  line-height: 16px;
}
.calendar-activity .card .title[data-v-84e7f580] {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 21px;
  background: rgba(48, 54, 82, 0.73);
  font-weight: 400;
  font-size: 12px;
  color: #d0ccc4;
  line-height: 21px;
  padding: 0 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  text-align: center;
}
.ellipsis[data-v-84e7f580] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-84e7f580] {
  *zoom: 1;
}
.clearfix[data-v-84e7f580]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-84e7f580] {
  float: left;
}
.fr[data-v-84e7f580] {
  float: right;
}

.notice-list > .title[data-v-20c30a54] {
  height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-text-color);
  line-height: 20px;
  margin-bottom: 6px;
}
.notice-list > .title .left[data-v-20c30a54] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.notice-list > .title .left .icon[data-v-20c30a54] {
  margin-right: 5px;
  color: var(--zs-card-pool-icon-color);
  font-size: 24px;
}
.notice-list > .title .right[data-v-20c30a54] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-more-color);
}
.notice-list .list-item[data-v-20c30a54] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 8px;
}
.notice-list .list-item[data-v-20c30a54]:last-child {
  margin-bottom: 0;
}
.notice-list .list-item .notice-tag-color[data-v-20c30a54] {
  font-weight: 500;
  font-size: 13px;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-red[data-v-20c30a54] {
  background: none;
  color: #ffb116;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-orange[data-v-20c30a54] {
  background: none;
  color: #2e7cfd;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-green[data-v-20c30a54] {
  background: none;
  color: #00cb1e;
}
.notice-list .list-item .title[data-v-20c30a54] {
  margin-left: 10px;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-notice-color);
  line-height: 13px;
  display: inline-block;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notice-list .list-item .type[data-v-20c30a54] {
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-notice-color);
  line-height: 13px;
}
.ellipsis[data-v-20c30a54] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-20c30a54] {
  *zoom: 1;
}
.clearfix[data-v-20c30a54]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-20c30a54] {
  float: left;
}
.fr[data-v-20c30a54] {
  float: right;
}

.middle-box[data-v-ce0b4cdc] {
  width: 393px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 12px;
  padding: 10px;
}
.ellipsis[data-v-ce0b4cdc] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-ce0b4cdc] {
  *zoom: 1;
}
.clearfix[data-v-ce0b4cdc]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-ce0b4cdc] {
  float: left;
}
.fr[data-v-ce0b4cdc] {
  float: right;
}

.model-tab-container[data-v-548bdb88] {
  padding: 0 3px;
}
.model-tab-container .nav-line[data-v-548bdb88] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  overflow: hidden;
}
.model-tab-container .nav-line .more[data-v-548bdb88] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  height: 20px;
  margin-left: 5px;
  padding: 2px 8px;
  color: #999;
  font-size: 12px;
  border-radius: 24px;
  background-color: #f4f4f4;
  cursor: pointer;
}
.model-tab-container .model-nav[data-v-548bdb88] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  margin-bottom: 8px;
  line-height: 30px;
  white-space: nowrap;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom: 1px solid #f3f3f3;
}
.model-tab-container .model-nav.hasMore[data-v-548bdb88] {
  width: calc(100% - 60px);
}
.model-tab-container .model-nav.iconPadding[data-v-548bdb88] {
  padding: 0 30px;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88] {
  overflow-x: auto;
  overflow-y: auto;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]::-webkit-scrollbar-track {
  background: transparent;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  position: absolute;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]:hover::-webkit-scrollbar-track {
  background: transparent;
}
.model-tab-container .model-nav.wap-nav .nav-content[data-v-548bdb88]:hover::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  cursor: pointer;
}
.model-tab-container .model-nav.wap-nav .nav-content span[data-v-548bdb88] {
  margin-right: 30px;
}
.model-tab-container .model-nav.pc-nav .nav-content[data-v-548bdb88] {
  overflow: hidden;
}
.model-tab-container .model-nav .icon-btn[data-v-548bdb88] {
  width: 24px;
  height: 100%;
  font-size: 18px;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
  background: #fff;
  color: #8B8B8B;
}
.model-tab-container .model-nav .icon-btn.left-btn[data-v-548bdb88] {
  left: 0;
}
.model-tab-container .model-nav .icon-btn.right-btn[data-v-548bdb88] {
  right: 0;
  text-align: right;
}
.model-tab-container .model-nav .nav-content[data-v-548bdb88] {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}
.model-tab-container .model-nav .nav-content[data-v-548bdb88]::-webkit-scrollbar {
  display: none;
}
.model-tab-container .model-nav span[data-v-548bdb88] {
  margin-right: 20px;
  color: #8B8B8B;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  position: relative;
  min-width: 30px;
  text-align: center;
}
.model-tab-container .model-nav span[data-v-548bdb88]:hover {
  color: rgba(64, 64, 64, 0.5);
}
.model-tab-container .model-nav span.active[data-v-548bdb88] {
  color: #a77334;
}
.model-tab-container .model-nav span.active.line[data-v-548bdb88]:after {
  content: "";
  background: #be8e54;
  width: 20px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 2px;
}
.ba-model-tab-container .model-nav[data-v-548bdb88] {
  border-bottom: none;
  margin-bottom: 8px;
}
.ba-model-tab-container .model-nav span[data-v-548bdb88] {
  width: auto;
  height: 26px;
  padding: 0 16px;
  line-height: 26px;
  font-size: 12px;
  margin-right: 14px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOIAAABECAMAAACrixkWAAAAAXNSR0IArs4c6QAAAXRQTFRFAAAA////////////////zMzM1dXV////29vb39/f4+Pj5ubm0dHR29vb3d3d39/f7+/v1dXV4+Pj29vb5+fn3Nzc39/f4ODg6+vr2NjY4+Pj29vb3Nzc3d3d3t7e19fX29vb3Nzc2NjY3t7e29vb5+fn4+Pj3t7e2dnZ39/f29vb29vb3Nzc3d3d2dnZ2dnZ3t7e4+Pj29vb29vb5OTk2dnZ3d3d5ubm29vb3Nzc3d3d2tra4uLi29vb3Nzc2dnZ3Nzc4ODg2dnZ2tra2tra29vb29vb29vb4uLi4uLi2dnZ3Nzc4+Pj29vb3Nzc3d3d29vb29vb5OTk2tra4uLi2tra29vb29vb29vb2dnZ2tra3Nzc4eHh5OTk2tra4uLi5OTk29vb3Nzc4+Pj3Nzc29vb29vb29vb4uLi3Nzc4+Pj4+Pj29vb4+Pj29vb2tra3Nzc3Nzc4uLi2tra29vb3d3d4+Pj29vb3d3d39/f4eHh4+PjNA9BrwAAAHx0Uk5TAAECAwQFBgYHCAkKCw4PEBASEhUVFhgZGRobHB0eHyAjJCcnKystLi8wMTIzNDU2Njc4OTk8PD1AQkNERkdISUlJSktMTU5PT1BRUVJVWFlcXV9gYGFiY2RmZ2dnZ2hoaWptbW5wcXJzdXV2eHh5e3t8fH1+fn5/f39/f++ohmIAAAKGSURBVGje3dtXW1NREIXhQBJaVBARsTfAXlAUUKyggIIFEVCRIqKQiCBgvpyz/7wXmDktf+CsueTufdZkJmHvnckkqtA7sbRedimv4vJkT2OmZnWM7ziVKvVlk8D8cNkp1XRzXNi+6rTK/5KLCruKYkIHTyLCtpKa0ANaQ8LcgprQBxgKEQfUhK4CMBcIWzYlQ2QjIPZrhshWQJxRnDXAmgkbymrEfSGzRjztSbYpPDXibfAVQ+SKEceAiq8XIp1GXASgorUwgD/2YyO7+/9PnliInyzE41gpdKtnmhdGvB4QFT6SAeaGEUcJlycTIieMOE+0fIlZA3v5qrB+O0ZMdbdWAsaKhXiUREmEyCsjXk4IPYVZA3eM+FgoRC/MOGPEj+iMm4ijqSqs+x0fNhKzBr4F/z/VnDUwZcRuzVkD94z4UHPWwDkjvhcNkYIRN1RmTSzEHyY8pLIw/FiIb4x4UXJhAINGHJRcGMAFI77VnDVw0Ig/JRdG+DjjgOKXU4B3RjyvOWvggRHvSy4MoNuIU6IhctiI3yUXBvyqqwqbNBcGfLAQz0ouDOCREe9KLgzgkhFfa84aOGLEFYEQa/Xpdr3d7NtLf4g1kfMW4sn0z5razTpixJsyZ6d+FHnNiC9FQkx06zEjftY5G44gd+OH/DL3GOwjuWghduq0afTb6pgRryrd0wh36y0jPlcLsYo8ZcRZvRCdc879bTDimtSsCWomuIa6hdr9vv3qz4SPMxRD3GwJiHN6s8Y55wZC9/qHFGeNWwg/smlVDLHUFnlj80xPWOyKPpTKfVUTrrbHn7s1T0sBy8P55KPFbJ/Oe7Cd8Y7aT08beyaXU/+wr7y+NNFbSOL+AaKJXW7EDdNUAAAAAElFTkSuQmCC) no-repeat center / 100% 100%;
}
.ba-model-tab-container .model-nav span.active[data-v-548bdb88] {
  background: url(../img/item-nav-bg-active.97b7142b.png) no-repeat center / 100% 100%;
  color: #692b2b;
}
.ba-model-tab-container .model-nav span.active.line[data-v-548bdb88]:after {
  content: none;
}
.zsca2-model-tab-container .nav-line .more[data-v-548bdb88] {
  color: var(--zs-model-tab-more-text-color);
  background: var(--zs-model-tab-more-bg);
}
.zsca2-model-tab-container .model-nav[data-v-548bdb88] {
  border: none;
}
.zsca2-model-tab-container .model-nav span[data-v-548bdb88] {
  position: relative;
  display: inline-block;
  min-width: 30px;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  font-size: 12px;
  color: var(--zs-model-tab-title-text-color);
  line-height: 16px;
  margin-right: 30px;
}
.zsca2-model-tab-container .model-nav span[data-v-548bdb88]:hover,
.zsca2-model-tab-container .model-nav span.active[data-v-548bdb88] {
  font-weight: bold;
  font-size: 12px;
  color: var(--zs-model-tab-title-active-color);
  line-height: 16px;
}
.zsca2-model-tab-container .model-nav span:hover.line[data-v-548bdb88]:after,
.zsca2-model-tab-container .model-nav span.active.line[data-v-548bdb88]:after {
  content: none;
}
.ellipsis[data-v-548bdb88] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-548bdb88] {
  *zoom: 1;
}
.clearfix[data-v-548bdb88]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-548bdb88] {
  float: left;
}
.fr[data-v-548bdb88] {
  float: right;
}

.model-container[data-v-5b0ebd7b] {
  margin-bottom: 14px;
}
.model-container .model-title[data-v-5b0ebd7b] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 34px;
  font-weight: bold;
  font-size: 16px;
  color: var(--zs-server-tab-color);
  line-height: 34px;
  background: var(--zs-server-wrapper-bg);
  margin-bottom: 10px;
  border-radius: 4px;
}
.model-container .model-title .dot[data-v-5b0ebd7b] {
  display: block;
  width: 3px;
  height: 16px;
  background: var(--zs-model-title-before);
  margin: 0 7px 0 9px;
}
.ellipsis[data-v-5b0ebd7b] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-5b0ebd7b] {
  *zoom: 1;
}
.clearfix[data-v-5b0ebd7b]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-5b0ebd7b] {
  float: left;
}
.fr[data-v-5b0ebd7b] {
  float: right;
}

.entry-update[data-v-70f097d4] {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
}
.entry-update .title[data-v-70f097d4] {
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-qz-entry-title-color);
  line-height: 17px;
  margin-bottom: 12px;
}
.entry-update .list[data-v-70f097d4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 10px 10px;
}
.entry-update .list .item[data-v-70f097d4] {
  display: block;
  width: 169px;
  height: 28px;
  background: var(--zs-qz-entry-bg-color);
  border-radius: 2px;
  font-size: 12px;
  color: var(--zs-qz-entry-text-color);
  line-height: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 5px;
  gap: 12px;
}
.entry-update .list .item .item-title[data-v-70f097d4] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.entry-update .list .item .item-title[data-v-70f097d4]::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #555469;
  margin-right: 8px;
  margin-left: 5px;
}
.entry-update .list .item .date[data-v-70f097d4] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  color: var(--zs-qz-entry-date-color);
}
.ellipsis[data-v-70f097d4] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-70f097d4] {
  *zoom: 1;
}
.clearfix[data-v-70f097d4]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-70f097d4] {
  float: left;
}
.fr[data-v-70f097d4] {
  float: right;
}

.cdk-container[data-v-5e7e6e73] {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  overflow: hidden;
}
.cdk-container .title[data-v-5e7e6e73] {
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-qz-entry-title-color);
  line-height: 17px;
  margin-bottom: 12px;
}
.cdk-container .swiper-container-box[data-v-5e7e6e73] {
  position: relative;
  overflow: visible;
  padding-bottom: 18px;
}
.cdk-container .swiper-container-box .banner-item .flex-line[data-v-5e7e6e73] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 16px;
}
.cdk-container .swiper-container-box .banner-item .flex-line[data-v-5e7e6e73]:last-child {
  margin-bottom: 0;
}
.cdk-container .swiper-container-box .banner-item .flex-line .label[data-v-5e7e6e73] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  color: #999;
  font-size: 12px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box[data-v-5e7e6e73] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 28px;
  margin-left: 5px;
  border-radius: 4px;
  background: var(--zs-qz-entry-bg-color);
  padding: 2px 4px 2px 10px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .limit[data-v-5e7e6e73] {
  font-size: 12px;
  color: #666666;
  width: 56px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content[data-v-5e7e6e73] {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 0;
  background-color: var(--zs-qz-card-bg);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 22px;
  border-radius: 2px;
  padding-right: 2px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content .val[data-v-5e7e6e73] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 6px;
  color: var(--zs-qz-btn-bg);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content .btn[data-v-5e7e6e73] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 76px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  background: var(--zs-qz-btn-bg);
  cursor: pointer;
}
.cdk-container .swiper-container-box .swiper-pagination[data-v-5e7e6e73] {
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.cdk-container .swiper-container-box .swiper-pagination[data-v-5e7e6e73] .swiper-pagination-bullet {
  width: 15px;
  height: 4px;
  margin-right: 6px;
  border-radius: 2px;
  background: var(--zs-qz-btn-bg);
}
.cdk-container .swiper-container-box .swiper-pagination[data-v-5e7e6e73] .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.ellipsis[data-v-5e7e6e73] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-5e7e6e73] {
  *zoom: 1;
}
.clearfix[data-v-5e7e6e73]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-5e7e6e73] {
  float: left;
}
.fr[data-v-5e7e6e73] {
  float: right;
}

.qz-list[data-v-4a90bff4] {
  position: relative;
  width: 100%;
  padding: 10px 0;
  overflow: hidden;
  padding-bottom: 60px;
}
.qz-list .title[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 34px;
  margin: 0 10px;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--zs-server-wrapper-bg);
  border-radius: 4px;
}
.qz-list .title .left[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: var(--zs-server-tab-color);
  line-height: 24px;
}
.qz-list .title .left .dot[data-v-4a90bff4] {
  display: block;
  width: 3px;
  height: 16px;
  background: var(--zs-qz-tag-bg);
  margin-right: 6px;
}
.qz-list .title .right[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  color: var(--zs-qz-tag-icon-color);
  line-height: 16px;
  float: right;
  cursor: pointer;
}
.qz-list .title .right i[data-v-4a90bff4] {
  font-size: 8px;
  margin-left: 4px;
}
.qz-list .btns[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  width: 100%;
  padding: 14px 19px 13px;
}
.qz-list .btns .btn[data-v-4a90bff4] {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 158px;
  height: 32px;
  background: var(--zs-qz-btn-bg);
  border-radius: 16px;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
}
.qz-list .btns .btn .icon[data-v-4a90bff4] {
  font-size: 20px;
  margin-right: 3px;
}
.list-nav[data-v-4a90bff4] {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 0 11px;
  gap: 62px;
  border-bottom: 1px solid var(--zs-qz-nav-border-color);
  margin-bottom: 11px;
}
.list-nav .list-nav-item[data-v-4a90bff4] {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 21px;
  cursor: pointer;
}
.list-nav .list-nav-item.active[data-v-4a90bff4] {
  font-weight: bold;
  color: var(--zs-qz-tab-active-color);
}
.list-nav .active-bar[data-v-4a90bff4] {
  position: absolute;
  top: 41px;
  left: 46px;
  width: 40px;
  height: 3px;
  background: var(--zs-qz-tab-active-color);
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.list[data-v-4a90bff4] {
  padding: 0 10px;
  overflow: hidden;
}
.list .list-item[data-v-4a90bff4] {
  display: block;
  padding: 4px 0 13px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
}
.list .list-item[data-v-4a90bff4]:last-child {
  margin-bottom: 0;
}
.list .list-item .top[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;
}
.list .list-item .top .left[data-v-4a90bff4] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-qz-nav-item-text-color);
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list .list-item .top .left .is-top[data-v-4a90bff4] {
  color: #0096ff;
  margin-right: 4px;
}
.list .list-item .top .right[data-v-4a90bff4] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
}
.list .list-item .top .right .icon[data-v-4a90bff4] {
  width: 20px;
  height: 20px;
}
.list .list-item .middle[data-v-4a90bff4] {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 15px;
}
.list .list-item .bottom[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.list .list-item .bottom .user[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 11px;
  color: #999;
  line-height: 16px;
}
.list .list-item .bottom .user .avatar[data-v-4a90bff4] {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 6px;
}
.list .list-item .bottom .data[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
}
.list .list-item .bottom .data .data-item[data-v-4a90bff4] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  font-size: 11px;
  color: #999999;
}
.list .list-item .bottom .data .data-item .icon[data-v-4a90bff4] {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
.list .quyunba-box[data-v-4a90bff4] {
  width: 100%;
}
.list .quyunba-box img[data-v-4a90bff4] {
  width: 100%;
}
.list .battle-banner[data-v-4a90bff4] {
  width: 100%;
  height: 60px;
  background: url(../img/home_banner.7c1161f8.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.list .ck-banner[data-v-4a90bff4] {
  width: 100%;
  height: 60px;
  background: url(../img/ck-entry-banner.fd6575b8.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.more[data-v-4a90bff4] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}
.ellipsis[data-v-4a90bff4] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-4a90bff4] {
  *zoom: 1;
}
.clearfix[data-v-4a90bff4]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-4a90bff4] {
  float: left;
}
.fr[data-v-4a90bff4] {
  float: right;
}


[data-v-19614df6] .shenqing-dialog {
  overflow: hidden;
}
[data-v-19614df6] .shenqing-dialog .el-dialog__header {
  padding: 0;
  background: #404040;
}
[data-v-19614df6] .shenqing-dialog .el-dialog__header .game-info {
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding-left: 10px;
}
[data-v-19614df6] .shenqing-dialog .el-dialog__header .game-info img {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #fff;
  margin-right: 10px;
}
[data-v-19614df6] .shenqing-dialog .el-dialog__header .el-dialog__close {
  color: #fff;
}
[data-v-19614df6] .shenqing-dialog .title {
  color: #171717;
  font-size: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px 0;
  margin-bottom: 20px;
}
[data-v-19614df6] .shenqing-dialog .input-box {
  margin-bottom: 30px;
}
[data-v-19614df6] .shenqing-dialog .input-box .text {
  color: #171717;
  font-size: 14px;
}
[data-v-19614df6] .shenqing-dialog .input-box .text span {
  color: #FC6475;
}
[data-v-19614df6] .shenqing-dialog .input-box textarea {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  resize: none;
  height: 120px;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}
[data-v-19614df6] .shenqing-dialog .ok-btn {
  width: 100%;
  margin-bottom: 10px;
}
.ellipsis[data-v-19614df6] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-19614df6] {
  *zoom: 1;
}
.clearfix[data-v-19614df6]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-19614df6] {
  float: left;
}
.fr[data-v-19614df6] {
  float: right;
}

.popover-box {
  padding: 0;
  border-radius: 15px;
  background: transparent;
  border: none;
}
.popover-box .container {
  position: relative;
  width: 100%;
  min-height: 120px;
  border-radius: 15px;
  background: var(--zs-aside-bar-pop-bg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 19px 11px;
  border-radius: 16px;
}
.popover-box .container .title {
  position: relative;
  z-index: 2;
  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeAAAAAQCAYAAADOMaw4AAAAAXNSR0IArs4c6QAAArZJREFUeF7tXYFNxDAMjGEwmAH2gA1APwLsATPAYLzB8EZWSErauvkqPqSqbYjt3Nmfw6/vQwk/YAAMgAEwAAbAQHcGqHtEBByWAWa+IaLXYQECGBgwDKDeUQ5rGYAAr2UQ9t8MMPNTSukupfRMRPegBQyMzADqfeTs9sMGAe7H9bCRzGakGCHCw2YbwFDvqAEvBiDAXkwG9VPYjCDCQWshAmzUe4Qs98MIAe7H9XCRJjYjiPBw2QYg1DtqwJsBCLA3o0H8NWxGEOEgtRABJuo9Qpb7YyRmVhGW85pDVn9R8CHja/yWbEuxdKx07hU/jyN8eOG3mGv4PXC+EdH7VCnO2Iwgwv1f04jozIBXvTPzVUrpWj6zuPAQZMfMVsZyfzJHx+310rhiZ+OoTy/fLesqxW+xa51T47aE0QU3Ef2ILzOLUNhDxuX+8iSe+XU+v2Zvx60vb3tZr/hXv7r+1vgt9qU1q53lS/8IsWvJ16b3a+xbMZaw1XIh4wciOtT2MHn0IqX0smCPu8UjSgtYg8lZGfCsd2Z+/BLQByOispF/nO7lWsVVr+V3IiB6b886rvZyPzU/93EOe11jDaeu387LMZcw5mN5nLkcbWH/J49EdFQB9uicbCetnZ+KUd4FenWG6jePY+N7daC1TlyFsBTHC2ctPxa/F050wGfd8hF8bwzsqAMudXPauXl3vBor7wxL961d5pJ5FpftxFXES+8ALImT25R4rb0TsCjebwe8t4LHevbPwIxNCY8k7T+dWOE/DKDeUSJbMIAPYW3BahCfDZsSxDdILUSAiXqPkOW+GCHAffkeLhqeixwupQA0wQDqHeXhyQAE2JPNoL7wzUBBEx8UNuo9aOI3gA0B3oDUiC7x3bgRsx4XM+o9bu49kUOAPdkM7gv/HSZ4AQSDj3oPlvAN4H4Cec0ZL6wiV+YAAAAASUVORK5CYII=) no-repeat;
  background-size: contain;
  background-position: center center;
  margin-bottom: 22px;
}
.popover-box .container .content {
  position: relative;
  z-index: 2;
  padding: 12px;
  border-radius: 8px;
  background: var(--zs-aside-bar-pop-content-bg);
}
.popover-box .container .content .gg-content {
  font-weight: 400;
  font-size: 12px;
  color: var(--zs-aside-bar-pop-content-color);
  line-height: 18px;
}
.popover-box .container .content .friend-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 8px 8px;
}
.popover-box .container .content .friend-links .link-item {
  width: 110px;
  height: 24px;
  background: var(--zs-aside-bar-pop-friend-bg);
  border-radius: 2px;
  font-weight: 400;
  font-size: 12px;
  color: var(--zs-aside-bar-pop-friend-color);
  line-height: 24px;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.popover-box .container .content .friend-links .link-item:hover {
  opacity: 0.9;
}
.popover-box .container .content .friend-links .link-item img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  vertical-align: middle;
}
.popover-box .container .content .manage-user-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  gap: 18px 16px;
}
.popover-box .container .content .manage-user-list .user-item {
  width: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 6px;
}
.popover-box .container .content .manage-user-list .user-item .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.popover-box .container .content .manage-user-list .user-item .username {
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: var(--zs-aside-bar-pop-friend-color);
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.popover-box .container .content .apply-admin {
  width: 216px;
  height: 32px;
  background: var(--zs-aside-bar-pop-apply-admin-bg);
  border-radius: 16px;
  font-size: 14px;
  color: var(--zs-aside-bar-pop-apply-admin-text-color);
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  margin: 34px auto 10px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.aside-bar[data-v-7ce77e0a] {
  position: fixed;
  top: 72px;
  left: 50%;
  margin-left: 615px;
  width: 52px;
}
.aside-bar .item[data-v-7ce77e0a] {
  width: 52px;
  height: 52px;
  color: var(--zs-aside-bar-text-color);
  background: var(--zs-aside-bar-bg);
  border-radius: 29px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  margin-bottom: 14px;
}
.aside-bar .item[data-v-7ce77e0a]:hover {
  opacity: 0.9;
}
.aside-bar .item .icon[data-v-7ce77e0a] {
  font-size: 24px;
}
.aside-bar .item .label[data-v-7ce77e0a] {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
.ellipsis[data-v-7ce77e0a] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-7ce77e0a] {
  *zoom: 1;
}
.clearfix[data-v-7ce77e0a]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-7ce77e0a] {
  float: left;
}
.fr[data-v-7ce77e0a] {
  float: right;
}

.scroll-top[data-v-5dc93e05] {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 615px;
}
.scroll-top .item[data-v-5dc93e05] {
  width: 40px;
  height: 40px;
  color: var(--zs-aside-bar-text-color);
  background: var(--zs-aside-bar-bg);
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 12px;
}
.scroll-top .item[data-v-5dc93e05]:last-child {
  margin-bottom: 0;
}
.scroll-top .icon[data-v-5dc93e05] {
  font-size: 22px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ellipsis[data-v-5dc93e05] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-5dc93e05] {
  *zoom: 1;
}
.clearfix[data-v-5dc93e05]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-5dc93e05] {
  float: left;
}
.fr[data-v-5dc93e05] {
  float: right;
}

.left-aside-bar[data-v-68e50dc8] {
  position: fixed;
  top: 72px;
  right: 50%;
  margin-right: 615px;
  width: 52px;
}
.left-aside-bar .item[data-v-68e50dc8] {
  width: 52px;
  height: 52px;
  color: var(--zs-aside-bar-text-color);
  background: var(--zs-aside-bar-bg);
  border-radius: 29px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  margin-bottom: 14px;
}
.left-aside-bar .item .icon[data-v-68e50dc8] {
  font-size: 24px;
}
.left-aside-bar .item .label[data-v-68e50dc8] {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
.ellipsis[data-v-68e50dc8] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-68e50dc8] {
  *zoom: 1;
}
.clearfix[data-v-68e50dc8]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-68e50dc8] {
  float: left;
}
.fr[data-v-68e50dc8] {
  float: right;
}

.nav-grid[data-v-04aef865] {
  border-radius: 8px;
  background-color: var(--zs-qz-card-bg);
}
.nav-grid .model-title[data-v-04aef865] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 34px;
  font-weight: bold;
  font-size: 16px;
  color: var(--zs-server-tab-color);
  line-height: 34px;
  background: var(--zs-server-wrapper-bg);
  margin-bottom: 10px;
  border-radius: 4px;
}
.nav-grid .model-title .dot[data-v-04aef865] {
  display: block;
  width: 3px;
  height: 16px;
  background: var(--zs-model-title-before);
  margin: 0 7px 0 9px;
}
.nav-grid .grid[data-v-04aef865] {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 12px;
  gap: 12px;
}
.nav-grid .grid .item[data-v-04aef865] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background-color: var(--zs-qz-nav-item-bg);
  border-radius: 6px;
  padding: 10px 0;
  gap: 10px;
  cursor: pointer;
}
.nav-grid .grid .item .icon[data-v-04aef865] {
  width: 50px;
  height: 50px;
}
.nav-grid .grid .item .txt[data-v-04aef865] {
  color: var(--zs-qz-entry-text-color);
  font-size: 12px;
  font-weight: 600;
}
.nav-grid .grid .item[data-v-04aef865]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-animation: scale 0.2s ease-in-out;
          animation: scale 0.2s ease-in-out;
}
.ellipsis[data-v-04aef865] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-04aef865] {
  *zoom: 1;
}
.clearfix[data-v-04aef865]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-04aef865] {
  float: left;
}
.fr[data-v-04aef865] {
  float: right;
}

.zsca-wiki-home[data-v-70b65fe3] {
  width: 100%;
  min-height: 100vh;
  padding-top: 65px;
}
.zsca-wiki-home .bg[data-v-70b65fe3] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: var(--zs-wiki-home-bg-img) no-repeat;
  background-size: 100% 100%;
}
.zsca-wiki-home .wiki-home-main[data-v-70b65fe3] {
  position: relative;
  width: 1216px;
  margin: 0 auto;
  background: var(--zs-wiki-home-main-bg);
  border-radius: 15px;
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.zsca-wiki-home .wiki-home-main .left[data-v-70b65fe3] {
  width: 817px;
}
.zsca-wiki-home .wiki-home-main .left .top-box[data-v-70b65fe3] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.zsca-wiki-home .wiki-home-main .left .bottom-box[data-v-70b65fe3] {
  width: 100%;
  background: var(--zs-wiki-banner-bg);
  padding: 10px;
  border-radius: 8px;
}
.zsca-wiki-home .wiki-home-main .right[data-v-70b65fe3] {
  width: 369px;
  background-color: var(--zs-wiki-banner-bg);
  border-radius: 8px;
}
.ellipsis[data-v-70b65fe3] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-70b65fe3] {
  *zoom: 1;
}
.clearfix[data-v-70b65fe3]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-70b65fe3] {
  float: left;
}
.fr[data-v-70b65fe3] {
  float: right;
}


/*# sourceMappingURL=chunk-1aca6386.04b5ccd6.css.map*/