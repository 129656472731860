































































.scroll-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 615px;
  .item {
    width: 40px;
    height: 40px;
    color: var(--zs-aside-bar-text-color);
    background: var(--zs-aside-bar-bg);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: all 0.3s;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon {
    font-size: 22px;
    transition: all 0.3s;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";