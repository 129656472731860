 /deep/ .shenqing-dialog {
  overflow: hidden;
}
 /deep/ .shenqing-dialog .el-dialog__header {
  padding: 0;
  background: #404040;
}
 /deep/ .shenqing-dialog .el-dialog__header .game-info {
  height: 60px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding-left: 10px;
}
 /deep/ .shenqing-dialog .el-dialog__header .game-info img {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #fff;
  margin-right: 10px;
}
 /deep/ .shenqing-dialog .el-dialog__header .el-dialog__close {
  color: #fff;
}
 /deep/ .shenqing-dialog .title {
  color: #171717;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 20px;
}
 /deep/ .shenqing-dialog .input-box {
  margin-bottom: 30px;
}
 /deep/ .shenqing-dialog .input-box .text {
  color: #171717;
  font-size: 14px;
}
 /deep/ .shenqing-dialog .input-box .text span {
  color: #FC6475;
}
 /deep/ .shenqing-dialog .input-box textarea {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  resize: none;
  height: 120px;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}
 /deep/ .shenqing-dialog .ok-btn {
  width: 100%;
  margin-bottom: 10px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
