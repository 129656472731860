



































































































































































































































.zsca-wiki-home {
  width: 100%;
  min-height: 100vh;
  padding-top: 65px;

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    // width: 100vw;
    // height: 100vh;
    background: var(--zs-wiki-home-bg-img) no-repeat;
    background-size: 100% 100%;
  }
  .wiki-home-main {
    position: relative;
    width: 1216px;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.25);
    background: var(--zs-wiki-home-main-bg);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    gap: 10px;
    .left {
      width: 817px;
      .top-box {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
      }
      .bottom-box {
        width: 100%;
        background: var(--zs-wiki-banner-bg);
        padding: 10px;
        border-radius: 8px;
      }
    }
    .right {
      width: 369px;
      background-color: var(--zs-wiki-banner-bg);
      border-radius: 8px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";