.card-pool {
  width: 100%;
  margin-bottom: 13px;
}
.card-pool .logo-title {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 13px;
  color: var(--zs-card-pool-text-color);
  margin-bottom: 6px;
}
.card-pool .logo-title .left {
  display: flex;
  align-items: center;
}
.card-pool .logo-title .left .icon {
  margin-right: 5px;
  color: var(--zs-card-pool-icon-color);
  font-size: 24px;
}
.card-pool .logo-title .quilt-card {
  height: 20px;
  width: 90px;
  color: #fff;
  background: var(--zs-qz-btn-bg) url("~@/assets/zs_imgs/ck-entry-icon.png") no-repeat;
  border-radius: 20px;
  line-height: 20px;
  background-position: 10px center;
  padding: 0 10px 0 30px;
  text-align: right;
  font-size: 10px;
  background-size: 16px 16px;
}
.card-pool .logo-title .right {
  display: flex;
  align-items: center;
  color: var(--zs-card-pool-more-color);
}
.card-pool .list {
  width: 100%;
  padding: 0 0 0 0;
}
.card-pool .list .scroll-wrapper {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.card-pool .list .scroll-wrapper .scroll-content {
  display: inline-block;
}
.card-pool .card {
  display: inline-block;
  width: 73px;
  height: 103px;
  background: var(--zs-card-pool-list-bg);
  border-radius: 8px;
  margin-right: 8px;
}
.card-pool .card .icon {
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.card-pool .card .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  background: #e3e3e3;
}
.card-pool .card .icon .time-left {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 29px;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 11px 4px 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 42%, rgba(0, 0, 0, 0.72) 100%);
}
.card-pool .card .icon .time-left .icon {
  width: 11px;
  height: 11px;
  margin-right: 2px;
  background: transparent;
}
.card-pool .card .icon .tag {
  position: absolute;
  right: 4px;
  top: 4px;
  height: 16px;
  padding: 0 6px;
  background: #d27246;
  border-radius: 8px;
  font-size: 10px;
  color: #d5f9ff;
  line-height: 16px;
}
.card-pool .card .name {
  width: 100%;
  height: 23px;
  padding: 0 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--zs-card-pool-name-color);
  line-height: 23px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
