.notice-list > .title {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-text-color);
  line-height: 20px;
  margin-bottom: 6px;
}
.notice-list > .title .left {
  display: flex;
  align-items: center;
}
.notice-list > .title .left .icon {
  margin-right: 5px;
  color: var(--zs-card-pool-icon-color);
  font-size: 24px;
}
.notice-list > .title .right {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-more-color);
}
.notice-list .list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.notice-list .list-item:last-child {
  margin-bottom: 0;
}
.notice-list .list-item .notice-tag-color {
  font-weight: 500;
  font-size: 13px;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-red {
  background: none;
  color: #ffb116;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-orange {
  background: none;
  color: #2e7cfd;
}
.notice-list .list-item .notice-tag-color.notice-tag-color-green {
  background: none;
  color: #00cb1e;
}
.notice-list .list-item .title {
  margin-left: 10px;
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-notice-color);
  line-height: 13px;
  display: inline-block;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notice-list .list-item .type {
  font-weight: 400;
  font-size: 13px;
  color: var(--zs-card-pool-notice-color);
  line-height: 13px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
