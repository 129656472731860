.entry-update {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
}
.entry-update .title {
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-qz-entry-title-color);
  line-height: 17px;
  margin-bottom: 12px;
}
.entry-update .list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
}
.entry-update .list .item {
  display: block;
  width: 169px;
  height: 28px;
  background: var(--zs-qz-entry-bg-color);
  border-radius: 2px;
  font-size: 12px;
  color: var(--zs-qz-entry-text-color);
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  gap: 12px;
}
.entry-update .list .item .item-title {
  flex: 1;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.entry-update .list .item .item-title::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #555469;
  margin-right: 8px;
  margin-left: 5px;
}
.entry-update .list .item .date {
  flex-shrink: 0;
  color: var(--zs-qz-entry-date-color);
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
