























































.entry-update {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  .title {
    font-weight: bold;
    font-size: 13px;
    color: var(--zs-qz-entry-title-color);
    line-height: 17px;
    margin-bottom: 12px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 10px;
    .item {
      display: block;
      width: 169px;
      height: 28px;
      background: var(--zs-qz-entry-bg-color);
      border-radius: 2px;
      font-size: 12px;
      color: var(--zs-qz-entry-text-color);
      line-height: 16px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      gap: 12px;
      .item-title {
        flex: 1;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 3px;
          height: 3px;
          background: #555469;
          margin-right: 8px;
          margin-left: 5px;
        }
      }
      .date {
        flex-shrink: 0;
        color: var(--zs-qz-entry-date-color);
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";