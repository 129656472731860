




































































































.card-pool {
  width: 100%;
  margin-bottom: 13px;
  .logo-title {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    // line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .quilt-card {
      height: 20px;
      width: 90px;
      color: #fff;
      background: var(--zs-qz-btn-bg) url("~@/assets/zs_imgs/ck-entry-icon.png") no-repeat;
      border-radius: 20px;
      line-height: 20px;
      background-position: 10px center;
      padding: 0 10px 0 30px;
      text-align: right;
      font-size: 10px;
      background-size: 16px 16px;
    }
    .right {
      display: flex;
      align-items: center;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list {
    width: 100%;
    padding: 0 0 0 0;
    .scroll-wrapper {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      .scroll-content {
        display: inline-block;
      }
    }
  }
  .card {
    display: inline-block;
    width: 73px;
    height: 103px;
    background: var(--zs-card-pool-list-bg);
    border-radius: 8px;
    margin-right: 8px;
    .icon {
      position: relative;
      width: 100%;
      height: 80px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        background: #e3e3e3;
      }
      .time-left {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 29px;
        // background: rgba(19, 23, 33, 0.8);
        font-weight: 400;
        font-size: 10px;
        color: #fff;
        padding: 11px 4px 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 42%, rgba(0, 0, 0, 0.72) 100%);
        .icon {
          width: 11px;
          height: 11px;
          margin-right: 2px;
          background: transparent;
        }
      }
      .tag {
        position: absolute;
        right: 4px;
        top: 4px;
        height: 16px;
        padding: 0 6px;
        background: #d27246;
        border-radius: 8px;
        font-size: 10px;
        color: #d5f9ff;
        line-height: 16px;
      }
    }
    .name {
      width: 100%;
      height: 23px;
      padding: 0 4px;
      font-weight: 400;
      font-size: 12px;
      color: var(--zs-card-pool-name-color);
      line-height: 23px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";