
















































































































.notice-list {
  > .title {
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    color: var(--zs-card-pool-text-color);
    line-height: 20px;
    margin-bottom: 6px;
    .left {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 5px;
        color: var(--zs-card-pool-icon-color);
        font-size: 24px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-more-color);
    }
  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .notice-tag-color {
      font-weight: 500;
      font-size: 13px;
      &.notice-tag-color-red {
        background: none;
        color: #ffb116;
        // border: 1px solid #ffb116;
      }

      &.notice-tag-color-orange {
        background: none;
        color: #2e7cfd;
        // border: 1px solid #2e7cfd;
      }

      &.notice-tag-color-green {
        background: none;
        color: #00cb1e;
        // border: 1px solid #00cb1e;
      }
    }

    .title {
      margin-left: 10px;
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-notice-color);
      line-height: 13px;
      display: inline-block;
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .type {
      font-weight: 400;
      font-size: 13px;
      color: var(--zs-card-pool-notice-color);
      line-height: 13px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";