.nav-grid {
  border-radius: 8px;
  background-color: var(--zs-qz-card-bg);
}
.nav-grid .model-title {
  display: flex;
  align-items: center;
  height: 34px;
  font-weight: bold;
  font-size: 16px;
  color: var(--zs-server-tab-color);
  line-height: 34px;
  background: var(--zs-server-wrapper-bg);
  margin-bottom: 10px;
  border-radius: 4px;
}
.nav-grid .model-title .dot {
  display: block;
  width: 3px;
  height: 16px;
  background: var(--zs-model-title-before);
  margin: 0 7px 0 9px;
}
.nav-grid .grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 12px;
  gap: 12px;
}
.nav-grid .grid .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--zs-qz-nav-item-bg);
  border-radius: 6px;
  padding: 10px 0;
  gap: 10px;
  cursor: pointer;
}
.nav-grid .grid .item .icon {
  width: 50px;
  height: 50px;
}
.nav-grid .grid .item .txt {
  color: var(--zs-qz-entry-text-color);
  font-size: 12px;
  font-weight: 600;
}
.nav-grid .grid .item:hover {
  transform: scale(1.05);
  animation: scale 0.2s ease-in-out;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
