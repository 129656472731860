














































































































.cdk-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  overflow: hidden;
  .title {
    font-weight: bold;
    font-size: 13px;
    color: var(--zs-qz-entry-title-color);
    line-height: 17px;
    margin-bottom: 12px;
  }
  .swiper-container-box {
    position: relative;
    overflow: visible;
    padding-bottom: 18px;
    .banner-item {
      .flex-line {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          flex: 0 0 auto;
          color: #999;
          font-size: 12px;
        }
        .code-box {
          flex: 1;
          display: flex;
          align-items: center;
          height: 28px;
          margin-left: 5px;
          border-radius: 4px;
          background: var(--zs-qz-entry-bg-color);
          padding: 2px 4px 2px 10px;
          .limit {
            font-size: 12px;
            color: #666666;
            width: 56px;
          }
          .content {
            flex-grow: 1;
            width: 0;
            background-color: var(--zs-qz-card-bg);
            display: flex;
            align-items: center;
            height: 22px;
            border-radius: 2px;
            padding-right: 2px;
            .val {
              flex: 1;
              padding: 0 6px;
              color: var(--zs-qz-btn-bg);
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 auto;
              width: 76px;
              height: 18px;
              line-height: 18px;
              border-radius: 4px;
              color: #ffffff;
              font-size: 12px;
              background: var(--zs-qz-btn-bg);
              cursor: pointer;
            }
          }
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      /deep/.swiper-pagination-bullet {
        width: 15px;
        height: 4px;
        margin-right: 6px;
        border-radius: 2px;
        background: var(--zs-qz-btn-bg);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";