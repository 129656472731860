.server-select-menu {
  background: none !important;
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.server-select-menu /deep/ .el-dropdown-menu__item {
  font-size: 13px;
  font-weight: 400;
  color: var(--zs-server-select-color);
  background: var(--zs-server-select-bg) !important;
  height: 23px;
  min-width: 72px;
  border-radius: 11px;
  margin-bottom: 4px;
  line-height: 23px;
  padding: 0 12px;
  font-size: 11px;
  border: 1px solid var(--zs-server-dropdown-border-color);
}
.server-select-menu /deep/ .el-dropdown-menu__item:focus {
  background-color: #292a30 !important;
}
.server-select-menu /deep/ .popper__arrow {
  display: none !important;
}
.server-select {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 8px;
}
.server-select .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 3px 5px;
  background-color: var(--zs-server-wrapper-bg);
  border-radius: 4px;
}
.server-select .wrapper .left {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-server-tab-color);
  line-height: 20px;
}
.server-select .wrapper .left .dot {
  width: 3px;
  height: 3px;
  background: var(--zs-server-tab-dot-bg);
  margin-right: 7px;
  border-radius: 50%;
}
.server-select .server-select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--zs-server-select-bg);
  border-radius: 4px;
  padding: 0 11px 0 14px;
  min-width: 79px;
  height: 26px;
  line-height: 26px;
  font-weight: 400;
  font-size: 11px;
  color: var(--zs-server-select-color);
}
.server-select .server-select-box .icon {
  margin-left: 4px;
  color: var(--zs-server-select-color);
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
