.left-aside-bar {
  position: fixed;
  top: 72px;
  right: 50%;
  margin-right: 615px;
  width: 52px;
}
.left-aside-bar .item {
  width: 52px;
  height: 52px;
  color: var(--zs-aside-bar-text-color);
  background: var(--zs-aside-bar-bg);
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 14px;
}
.left-aside-bar .item .icon {
  font-size: 24px;
}
.left-aside-bar .item .label {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
