.cdk-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--zs-qz-nav-item-border-color);
  overflow: hidden;
}
.cdk-container .title {
  font-weight: bold;
  font-size: 13px;
  color: var(--zs-qz-entry-title-color);
  line-height: 17px;
  margin-bottom: 12px;
}
.cdk-container .swiper-container-box {
  position: relative;
  overflow: visible;
  padding-bottom: 18px;
}
.cdk-container .swiper-container-box .banner-item .flex-line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.cdk-container .swiper-container-box .banner-item .flex-line:last-child {
  margin-bottom: 0;
}
.cdk-container .swiper-container-box .banner-item .flex-line .label {
  flex: 0 0 auto;
  color: #999;
  font-size: 12px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box {
  flex: 1;
  display: flex;
  align-items: center;
  height: 28px;
  margin-left: 5px;
  border-radius: 4px;
  background: var(--zs-qz-entry-bg-color);
  padding: 2px 4px 2px 10px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .limit {
  font-size: 12px;
  color: #666666;
  width: 56px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content {
  flex-grow: 1;
  width: 0;
  background-color: var(--zs-qz-card-bg);
  display: flex;
  align-items: center;
  height: 22px;
  border-radius: 2px;
  padding-right: 2px;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content .val {
  flex: 1;
  padding: 0 6px;
  color: var(--zs-qz-btn-bg);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cdk-container .swiper-container-box .banner-item .flex-line .code-box .content .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 76px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  background: var(--zs-qz-btn-bg);
  cursor: pointer;
}
.cdk-container .swiper-container-box .swiper-pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.cdk-container .swiper-container-box .swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 15px;
  height: 4px;
  margin-right: 6px;
  border-radius: 2px;
  background: var(--zs-qz-btn-bg);
}
.cdk-container .swiper-container-box .swiper-pagination /deep/ .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
