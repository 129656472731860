











































.left-aside-bar {
  position: fixed;
  top: 72px;
  right: 50%;
  margin-right: 615px;
  width: 52px;
  .item {
    width: 52px;
    height: 52px;
    color: var(--zs-aside-bar-text-color);
    background: var(--zs-aside-bar-bg);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 14px;
    .icon {
      font-size: 24px;
    }
    .label {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";